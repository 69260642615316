body{
  &.edit_mode{
    a[href*='edit_header_code']{
      display: none;
    }
  }
  &#edit-head-code {
    a[href*='edit_header_code']{
      display: block;
    }
  }
}

.custom-cta {
  .sn-call-to-action-overlay {
    background-color: rgba(40, 40, 40, 0.6);
    background-blend-mode: multiply;
    &:hover {
          background-color: rgba(255, 139, 0, .8);
          transition: background-color .2s linear;
          p.sn-call-to-action-subtitle a  {
          text-decoration: none;
          background-color: #000000;
          transition: background-color .2s linear;


        }
      }

    }
    // &:hover {
    //   .sn-call-to-action-subtitle a  {
    //   text-decoration: none;
    //   background-color: #000000;
    //   transition: background-color .2s linear;
    //
    //   }
    // }
    .sn-call-to-action:before {
    display: none;
  }
  .sn-call-to-action .sn-call-to-action-overlay-text {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    .sn-call-to-action-title {
      font-weight:600;
      font-size:14px;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        position: relative;
        display: inline-block;
        // top: 3px;
        // left: 118px;
        right: -20px;
        height: 2px;
        width: 188px;
        background: #ffffff;
        .has-mobile-nav & {
          width:100%;
        }
      }
    }
    .sn-call-to-action-subtitle {
      font-size: 22px;
      line-height: 1.2;
      font-weight: 800;
      text-transform: uppercase;
      color: #ffffff;
      span {
        margin: 0;
        margin-bottom: 1rem;
      }

      a {

        background-color: $red;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        border: 2px solid $lt-g;
        padding: 15px 30px 15px 37px;
        transition: background-color .2s linear;

        &:after{
          content:'\f178';
          font-family: FontAwesome;
          margin-left: 7px;
          // margin-right: 10px;
        }
        &:hover{
          text-decoration: none;
          background-color: #000000;
          transition: background-color .2s linear;
        }
      }
    }
  }
  .sn-call-to-action:after {
    display: none;
  }
}
