.user_mode{
  .social-media-nav{
    display: inline-block;
    margin-top: -25px;
  .pageElement.sn-social-media-list.light.md{
    // margin-top: -2px;
  }
  .sn-social-media-list.md{
    font-size: 20px;
  }

  }
  .sn-social-media-list.light .sn-social-media-icon{
    &:hover{
      background: $orange;
    }
  }

}
.has-mobile-nav {
  .mobileNavContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100%;
        img {
          height: 32px;
          width: 126px;
        }
      }
    }
    .edit_mode {
      .mobile-nav-logo{
        img {
          height: 100px;
          width:auto;
        }
      }
    }
