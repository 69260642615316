html, body {
  overflow-x: hidden;
  background: $lt-g;
}
// .column {
//   padding-top:10px;
//   padding-bottom: 10px;
// }

.full-width {
  .user_mode & {
    width: 100vw;
    max-width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position:relative;
    padding:10px 0;
    &.content-padding  {
      padding: 10px calc((100vw - 1200px)/2);
    }
  }
  &.page-manager-visible & {
    padding: 0px calc((100vw - 1260px)/2);
  }
  .has-mobile-nav & {
    padding:0px;
    .column {
      padding:0;
    }
  }
  &.no-padding  {
    padding:0;
    .column {
      padding:0;
      min-height: 0px;
    }

  }

  }
  .top-media-slider {

    .mediaSlider{
      margin:0;
      p {
        display: none;
      }
      .sn-media-slider{
        height: 400px;
        .has-mobile-nav & {
          height: initial;
          // min-height: 275px;
          max-height: 275px;
        }
            .slides{
              height: 400px;
              .has-mobile-nav & {
                height: initial;
                min-height: 275px;
              }
              .slide{
                height: 400px;
                .has-mobile-nav & {
                  height: initial;
                  min-height: 275px;
                }
                .slide-overlay{
                  height: 400px!important;
                  background-color: rgba(0, 0, 0, 0.6);
                  background-blend-mode: multiply;
                  .has-mobile-nav & {
                    height: initial!important;
                    min-height: 275px;
                    padding: 5% 10%;
                  }

                }
                .media-wrapper{
                  height: 400px;
                  .has-mobile-nav & {
                    height: initial;
                    min-height: 275px;
                  }
                  a {
                    img {
                      height: 400px!important;
                      width: auto!important;
                      left: 50%!important;
                      transform: translateX(-50%);
                      .has-mobile-nav & {
                        height: initial!important;
                        min-height: 275px;
                      }


                    }
                  }
                }
              }
            }
          }
        }
        .sn-media-slider {
          .slider-pagination {
        bottom: 30px!important;
        .has-mobile-nav & {
          bottom: 15px !important;
        }
          }
          .slider {
            margin:0;
            .slide-title {
              font-size: 400%;
              font-weight: 800;
              margin:2% 0;
              .has-mobile-nav & {
                font-size: 225%;
              }

            }
            .slide-description {
              font-size: 115%;
              font-weight: 400;
              margin: 0% 25%;
              text-transform: none;
              .has-mobile-nav & {
                font-size: 100%;
                margin:0;
              }
            }
          }
        }
      }
      .top-media-slider.no-overlay {
        .mediaSlider .sn-media-slider .slides .slide {
          .slide-overlay {
            background: transparent!important;
          }
        }
      }

.user_mode{
          .tab-slider {
            height: 500px;
            width: 735px;
            .has-mobile-nav & {
              height: initial;
              min-height: 265px;
              max-height:265px;
              width: 100%;
            }
            .sn-media-slider{
              height: 500px;
              width: 735px;
              .has-mobile-nav & {
                height: initial;
                min-height: 265px;
                max-height:265px;
                width: 100%;
              }
              .slide{
                height: 500px;
                width: 735px;
                .has-mobile-nav & {
                  height: initial;
                  min-height: 265px;
                  // max-height:265px;
                  width: 100%;
                }
                .slide-overlay{
                  padding:5% 10%;
                  background-color: rgba(0, 0, 0, 0.6);
                  background-blend-mode: multiply;
                  height: calc(100% + 1px);
                  width: calc(100% + 1px);
                  .has-mobile-nav & {
                    padding: 3% 5% 25% 5%;
                    min-height: 265px;
                  }
                }
                .has-mobile-nav & {
                  .media-wrapper {
                    a {
                      img {
                        // max-height: 265px;
                        width: auto;
                      }
                    }
                  }
                }
              }
              .slider{
                .slide-title {
                  font-size: 300%;
                  font-weight: 800;
                  text-transform: uppercase;
                  margin:0;
                  line-height: 120%;
                  text-shadow: none;
                  padding-top:10px;
                  .has-mobile-nav & {
                    font-size: 170%;
                  }
                }
                .slide-description {
                  font-size: 115%;
                  font-weight: 400;
                  text-transform: none;
                  text-shadow: none;
                  padding-bottom: 10px;
                  .has-mobile-nav & {
                    font-size: 90%;
                    // padding-bottom: 15px;
                    margin:7px 0;
                  }
                }
              }
            }
              .sn-media-slider {
                .slider-pagination {
                  bottom:50px!important;
                  .has-mobile-nav & {
                    bottom:38px!important;
                  }
              }
            }
          }
        }


            .sn-media-slider .slider-pagination .paging-item {
              background: #ffffff!important;
              height: 10px!important;
              width: 10px!important;
              border: none;

            }
            .sn-media-slider .slider-pagination .paging-item.flex-active {
              background: $md-g!important;
              border: 2px solid #ffffff;
              height: 12px!important;
              width: 12px!important;
            }

    .sn-media-slider .slider.bottom-center-paging .flex-direction-nav a {
      transform: translate(0, 0);
      &:before  {
          font-size: 32px;
          text-shadow: none;
      }
    }

    .sponsor-container{
    background: #ffffff;
    display: flex;
    justify-content: center;
    .heroPhotoElement {
      img {
        // height: initial;
        // width: initial;
        max-width: 728px;
        max-height: 90px;
        .has-mobile-nav & {
          // width: inherit;
          // height: inherit;
          padding: 0 15px;
        }
  }
    }
}
  .social-container {
        background: $lt-g;
        h3 {
          font-weight: 800;
          font-size: 300%;
          .has-mobile-nav & {
            font-size: 170%;
          }
          span{
            box-shadow: 0px 0px 0px $md-g;
            padding-bottom: 10px;
          }
        }
        .column {
          display: flex;
          justify-content: center;
        }
}
.user_mode {
  .fix-text {
.textBlockElement {
  .text {
    display: flex;
    flex-direction: column;
  }
}
}
}
.column {
  min-height: 0px;
}


.sn-media-slider .slider.sn-breakpoint-lt-300 .slide-description {
  display: flex;
}


.tabbedElement {
  .has-mobile-nav & {
    .layoutContainer {
      display: flex!important;
      flex-direction: column!important;
    }
  }
}

.sn-media-slider .slider .slide-text {
  display: block!important;
}

.no-overlay {
  .mediaSlider .sn-media-slider .slides .slide {
    .slide-overlay {
      background: transparent!important;
    }
  }
}
