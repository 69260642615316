.user_mode{
  .tm-slider {
    height: 398px;
    .mediaSlider{
        height: 398px;
        margin: 0;
        p{
          display: none;
        }
      .sn-media-slider{
            height: 398px;
            .slides{
              height: 398px;
              .slide{
                height: 398px;
                .slide-overlay{
                  height: 398px!important;
                  // background-color: rgba(0, 0, 0, 0.6);
                  // background-blend-mode: multiply;
                  padding:2% 26%;
                .slide-description{
                  font-size: 140%;
                  line-height: 200%;
                  font-weight: 400;
                  margin: 0 0 1% 0;
                  }
                }
                .media-wrapper{
                  height: 398px;
                  a {
                    img {
                      // width: auto!important;
                      left: 50%!important;
                      transform: translateX(-50%);
                      filter: grayscale(100%);
                      .has-mobile-nav & {
                        height:398px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .sn-media-slider {
          .slider-pagination {
          height: 100px!important;
          }
        }
        .tm-author{
          color: #ffffff;
          line-height: 200%;
          font-weight: 700;
        }

      }
}
