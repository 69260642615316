/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  // width: 175px;
  // height:50px;
  h4{
    font-family: $fontOne;
    font-size: rem(12);
    font-weight: 600;
    a{
      background-color: $red;
      // box-shadow: 0 -3px $link-color-darken-10 inset;
      border: 2px solid $md-g;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 10px 20px 10px 10px;
      margin-bottom: 0px;
      // width: 175px;
      height:50px;
      // line-height: 1.3;
      // text-align: center;
      text-decoration: none;
      transition: background-color .2s;
      .custom-link & {
        width: 175px;
      }
      &:hover{
        background-color: #000000;
        transition: background-color .2s;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: #ffffff;;
      content: "\f178"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$red;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }

}

.quick-links{
  .pageElement{
    margin: 5px 0;
  }
  // height: 50px;
  .linkElement{
    h4 {
        font-size: rem(14);
      a {
        height: 45px;
        width: initial;
        background-color: #ffffff;
        color: $orange;
        border: none;
        transition: all .2s linear;
      }
    }
  }
  &:hover {
      h4 {
        a {
          background-color: $orange;
          color: #ffffff;
          transition: all .2s linear;
        }
      }

  }
}
