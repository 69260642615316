.user_mode .custom-survey table { display: none; }

  .user_mode .custom-survey {
    display: flex;
    flex-direction: column-reverse;
      .report-container {
        border: none;
    }
  }
  .row-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid $lt-g;
    padding:30px;
    h4 {
      font-size: 32px;
      font-weight: 800;
      text-transform: none;
      padding-bottom: 15px;
    }
    span {
      text-transform: uppercase;
      color: $purple;
      font-size: 14px;
      font-weight: 700;
    }
    .survey-info {
      display: flex;
      .has-mobile-nav & {
        flex-direction: column;
      }
      .survey-left {
        flex-basis: 50%;
        .tab-slider-link {
          .has-mobile-nav & {
            // position: absolute;
            // bottom: 0;
          }
        }
        ul {
        list-style-type: none;
        font-size: 16px;
        padding:20px 0;
        }
      }
      .survey-right {
        flex-basis: 50%;
        font-size: 16px;
          padding:20px 0;
        p {
          margin-top: 1rem;
        }
        hr {
          height:1px;
          background-color: $md-g;
        }
        a {
          color: $orange;
          text-transform: none;
          &:before{
            content:'\f178';
            font-family: FontAwesome;
            margin-right: 10px;
            transition: all .2s linear;
            }
            &:hover {
              text-decoration: none;
            }
            &:hover:before {
              margin-right: 10px;
              padding-left: 10px;
              transition: all .2s linear;
            }
        }

      }

    }

  }
  .survey-search {
    .textBlockElement {
      p {
        width: 50%;
        font-size: 16px;
        .has-mobile-nav & {
          width:100%;
        }
      }
    }
  .searchContainer {
    padding-left: 15px;
    display: flex;
    align-content: baseline;

    .surveyInput {
      padding:17px 15px;
      width: 275px;
      color: #000000;
      border: none;
      outline-color: transparent;
      outline-style: none;
      font: 400  14px $fontOne;
      .has-mobile-nav & {
        width: 129px;
      }
      &::placeholder {
        color: $md-g;
      }
    }
    .searchSubmit {
      background-color: $red;
      border: none;
      color: #ffffff;
      padding: 15px 33px;
      font: 400 16px $fontOne;
      transition: background-color .2s linear;
      outline-color: transparent;
       outline-style: none;
      .has-mobile-nav & {
        padding:15px 30px;
      }
      &:hover {
        background-color: #000000;
        transition: background-color .2s linear;
      }
    }
    .searchClear {
      background-color: transparent;
      font: 400 16px $fontOne;
      border: none;
      padding-left: 20px;
      color: $dk-g;
      transition: color .2s linear;
      outline-color: transparent;
      outline-style: none;
      .has-mobile-nav & {
        padding-left: 10px;
      }
      &:hover {
        color: $red;
        transition: color .2s linear;
      }
    }
  }
  }

  .edit_mode {
    table {
      .dataTable {
      td {
        border-right: 1px solid black;
        padding: 0 10px;
      }
    }
    }
  }


.no-results {
  font-size: 20px;
  font-weight: 700;
  padding:10px;
  margin-top: 20px;
  background: #ffffff;

}
