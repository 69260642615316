.sponsor-scroll {
  background: #fff;
  // height: 120px;
  // display: flex;
  align-items: center;

  .slick-slider {
    .slick-next:before,
    .slick-prev:before {
      color: $dk-g;
    }

    .slick-prev {
      left: 40px!important;
    }

    .slick-next {
      right: 40px!important;
    }

    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }

    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }

    .slick-slide {
      .heroPhotoElement {
        img {
          // padding: 30px;
          height: 74px;
          width: auto;
        }
      }
    }
  }

  .column {
    padding: 0;
  }

  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}

.tm-slick-slider {
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: top 18% center;
  height: 398px;
  z-index: 10;

  .edit_mode & {
    height: auto;
  }

  .full-width & {
    margin: 0;
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    // .has-mobile-nav & {
    //   display: block;
    // }
  }

  .textBlockElement {
    display: flex;
    flex-direction: column-reverse;
    // padding: 5% 18%;
    padding: 0 6%;
    width: 100%;

    .has-mobile-nav & {
      padding: 0 10px;
    }
    // padding:7% 20%;
    h3 {
      span {
        font-size: 20px;
        font-weight: 700;
        border-bottom: none;
        color: #ffffff;
      }
    }

    p {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 2;

      .has-mobile-nav & {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .slick-next:before {
    content: '\f105';
    font-family: FontAwesome;
    font-size: 40px;
  }

  .slick-prev:before {
    content: '\f104';
    font-family: FontAwesome;
    font-size: 40px;
  }

  .slick-dots li {
    margin: 0;
    opacity: 1;
    bottom: 30px;

    .has-mobile-nav & {
      bottom: -60px!important;
    }
    // display: flex;
    // align-items: baseline;
  }

  ul.slick-dots {
    .has-mobile-nav & {
      bottom: 50px!important;
    }
  }
  @media screen and (max-width: 300) {
    ul.slick-dots {
      display: none;
    }
  }

  .slick-dots li button:before {
    // font-size: 14px;
    content: '';
    // color: #ffffff;
    background: #ffffff;
    height: 8px !important;
    width: 8px !important;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background-color: $md-g;
    opacity: 1;
    border: 2px solid #ffffff;
    // height: 10px !important;
    // width: 10px !important;
  }
}

.tm-slick-slider-internal {
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: top 18% center;
  height: 398px;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .edit_mode & {
    height: auto;
  }

  .full-width & {
    margin: 0;
  }

  .has-mobile-nav & {
    display: block;
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    // .has-mobile-nav & {
    //   display: block;
    // }
  }
  // .slick-prev {
  //   left:-80px!important;
  // }
  // .slick-next {
  //   right:-80px!important;
  // }
  .textBlockElement {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 10%;
    width: 100%;
    // z-index: 5;
    .has-mobile-nav & {
      padding: 5% 15px;
    }
    // padding:7% 20%;
    h3 {
      span {
        font-size: 20px;
        font-weight: 700;
        border-bottom: none;
        color: #ffffff;
      }
    }

    p {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 2;

      .has-mobile-nav & {
        font-size: 14px;
        line-height: 1.5;
        // padding: 5px 15px;
      }
    }
  }

  .slick-prev {
    left: 30px;
  }

  .slick-next {
    right: 30px;
  }

  .slick-next:before {
    content: '\f105';
    font-family: FontAwesome;
    font-size: 40px;
  }

  .slick-prev:before {
    content: '\f104';
    font-family: FontAwesome;
    font-size: 40px;
  }

  .slick-dots li {
    margin: 0;
    opacity: 1;
    bottom: 30px;

    .has-main-nav & {
      bottom: 0px!important;
    }
    // display: flex;
    // align-items: baseline;
  }

  .slick-dots li button:before {
    // font-size: 14px;
    content: '';
    // color: #ffffff;
    background: #ffffff;
    height: 8px !important;
    width: 8px !important;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background-color: $md-g;
    opacity: 1;
    border: 2px solid #ffffff;
    // height: 10px !important;
    // width: 10px !important;
  }

  .slick-slider {
    z-index: 10;
  }
}

.behind-slider {
  .user_mode & {
    background-color: $dk-g;
    height: 398px;
    margin-top: -398px;
    // z-index: -1;
    width: 100vw;
    max-width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }
}

.behind-slider-internal {
  .user_mode & {
    background-color: $dk-g;
    height: 398px;
    margin-top: -398px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.slider-img {
  .user_mode & {
    height: 398px;
    margin-top: -398px;
    // z-index: -1;
    width: 100vw;
    max-width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 18% center;
    opacity: 0.1;
  }
}

.slider-img-internal {
  .user_mode & {
    height: 398px;
    margin-top: -398px;
    margin-left: 15px;
    margin-right: 15px;
    // z-index: -1;
    // width: 100vw;
    // max-width: 100vw;
    // left: 50%;
    // transform: translateX(-50%);
    // position:relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 18% center;
    opacity: 0.1;
  }
}

.slick-next,
.slick-prev {
  z-index: 100;
}