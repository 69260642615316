// 5. Site Footer
.snFooterContainer {
  width: 100%;
  // max-width: $maxWidth;
  margin: 0 auto;
  background-color: #000000;;
}

#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: .8em;
  clear: both;
  background-color: #000000;
  color: #ffffff;

  > ul {
    padding: 0;
    @include displayCenter($maxWidth);

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;

      a {
        display: inline-block;
        color: #ffffff;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;

    &:after {
      content: url($asset-path-for+'/logo_images/logo.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
  }
}
