
.user_mode{
  .footer-container{
    background: black;
    display: flex;
    justify-content: space-around;
    align-content: center;
    // padding: 0 calc((100vw - 1350px)/2);
    padding: 50px 30px;
    .has-mobile-nav & {
      flex-direction: column;
      align-items: center;
    }
    .ajax.footer {
      // flex: 1 1 auto;
      width: 100vw;
      .has-mobile-nav & {
        padding: 15px 0;
      }
      .pageElement {
        margin:0;

      }
        .textBlockElement {
          .has-mobile-nav & {
          text-align: center;
          }
          .text {
            // max-height: 128px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // width: 242px;
            line-height: 200%;
          }
        }
        h3 {
          font-size: 22px;
          // margin-bottom: 20px;
          .has-mobile-nav & {
            text-align: center!important;
          }
        }
        p {
          color: #ffffff;
          margin-bottom: 0;
        }
  }
    & h3, a{
      color: #ffffff!important;
      text-transform: none;
      // font-weight: 400;
    }
    & img {
      width: 155px;
      height:155px;
      max-width: 155px;
    }
    .pageElement.sn-social-media-list {
      display: flex;
      .has-mobile-nav & {
      justify-content: center;
    }
    }

  }


  .global-network-container {
    background: $lt-g;
    width:100%;
    padding: 0 calc((100vw - 1050px)/2);
    .has-mobile-nav & {
      padding:0 15px;
    }
  }

    .networkContainer{
      display: flex;
      background: $lt-g;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 0 calc((100vw - 1000px)/2);
      margin-top: -50px;
      padding: 10px 0;
      img {
        // height: 120px;
        height: 100px;
      }
      // filter: grayscale(100);
      //   &:hover img  {
      //     filter:grayscale(0);
      //   }
        .has-mobile-nav & {
          width: 100%;
          flex-flow: row wrap;
          align-content: center;
          justify-content: center;
          padding:0 6px;
          margin-top: 0px;
          filter: grayscale(0);
            img {
            max-height: 30px;
            width: auto;
          }
        }

      }
    }
    .edit_mode {
      .global-network, .global-footer {
        img {
          width: 250px;
          height: auto;
        }
      }
    }

.has-mobile-nav {
  .user_mode {
    .desktop-only {
      display: none;
    }
  }
}

.desktop-only {
  .user_mode & {
  .text {
  height: 128px;
  width: 242px;
}
}
}
