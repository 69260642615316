#siteContainer {
  &, #siteContainer.container-fluid {
    // padding: 15px 0;
    padding-bottom:0;
    width: 100%;
    // max-width: $maxWidth;
    max-width: 1200px;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: $lt-g;
    #home & {
      padding:0;
    }
    .home & {
      padding:0;
    }
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px 30px;
    font-size: 3em;
    font-weight: 800;
    border-bottom: $borderWidth solid $md-g;
    text-transform: uppercase;
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}
