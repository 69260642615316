.user_mode {
  .banner-img {
    .banner-container {
    position: relative;
    background: black;
  }
  .textBlockElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: multiply;
    margin: initial;
    height: 100%;
    .has-mobile-nav & {
      justify-content: baseline;
    }
    h3 {
      span {
        color: #ffffff;
        font-size: 200%;
        font-weight: 800;
        // order: 2;
      }
    }
    p {
        font-size: 225%;
        font-weight: 500;
        text-transform: uppercase;
    }
    .has-mobile-nav & {
      h3 {
        span {
              font-size: 65%;
        }
      }
      p {
          font-size: 100%;
          line-height: 1;
      }
    }
  }
  }
  .sponsor-logos {
    .heroPhotoElement {
      img {
        height: 124px;
        width: auto;
      }
    }
  }
}
