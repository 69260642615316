.user_mode {
.custom-tab {
  .tab-bg {
    display: none;
  }
// .user_mode & {
.pageEl {
  .tabbedElement {
    .has-mobile-nav & {
      padding: 0 15px;
    }
    ul.contentTabs {
      border: none;
      display: flex;
      align-items: center;
      justify-content:center;
      background: transparent;
      margin-bottom: -20px;
      .has-mobile-nav & {
        // flex-wrap: wrap;
        justify-content: center;
        padding: 0 15px;
        // margin-bottom: -61px;
      }
    &.layoutContainerTabs {
      // margin-bottom: 10px;
      &:before {
        display: block;
        content: '';
        height: 1px;
        width: 295px;
        position: absolute;
        background: $md-g;
        left: 15px !important;
        .has-mobile-nav & {
          width:6px;
          top:45px;
        }
      }
      &:after{
        display: block;
        content: '';
        height: 1px;
        width: 225px;
        position: absolute;
        background: $md-g;
        right: 15px !important;
        .has-mobile-nav & {
          width:6px;
          top:45px;
        }
      }
    }


      li {
        &.selected, &:hover {
          span{
            a{
              color: black;
              transition: color 0.2s ease-in;
            }
          }
        }
        span {
          a {
            display: block;
            color: $lt-g;
            border: 1px solid $md-g;
            padding:10px 30px;
            min-width: 180px;
            transition: color 0.2s ease-in;
              .has-mobile-nav & {
                min-width: inherit;
                padding: 10px 10px;
                max-height: 41px;
                display: flex;
                align-items: center;
                justify-content: center;

              }
          }


        }
      }
    }

    .tabContainer {
      border: 1px solid $md-g;
      border-top: none;
      padding-top: 28px;
      padding-left: 10px;
      .has-mobile-nav & {
        // margin-top: 61px;
        padding: 0 15px;
        .column:first-of-type {
          margin-top: 15px;
        }
      }
      // padding: 30px;
      // margin:20px;
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;

        }
      }
    }
  }
  .contentTabs {
    display: block;
    list-style-type: none;
    text-align: left;
    width: 100%;
    margin: 10px 0;
    // border-bottom: 3px solid #ececec;

    &:after { clear: both; }

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    li {
      @include content-tab-item;
      position: relative;

      span {
        background-repeat: no-repeat;
        background-position: bottom left;
        text-decoration: none;
        display: block;

        a {
          @include content-tab;

          span { display: inline; }
        }
      }

      &:hover,
      &.selected {
        background: $md-g;
        color: #000000!important;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 10px solid $md-g;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          .has-mobile-nav & {
            display: none;
          }
        }
      }
    }

    &.rapidTabsConstruction a.tabLink {
      padding-top: 4px;
      padding-left: 8px;
      display: inline-block;
      background: none;

    }
  }
// }
.tab-remote:nth-of-type(1)::before {
  content: '';
  height: 1px!important;
  width: 40px;
  .has-mobile-nav & {
    width: 0px;
  }
}
.tab-cta{
  // .user_mode & {
    background-repeat: no-repeat;
    background-size: cover;
    height: 240px;
    width: 345px;
    background-color: rgba(40, 40, 40, .6);
    background-blend-mode: multiply;
    display: flex;
    align-items: flex-end;
    transition: background-color .2s linear;
    margin-top: 28px;
    .has-mobile-nav & {
      // min-width: 315px;
      width: inherit;
    }
    &:hover {
      background-color: rgba(255, 139, 0, .8);
      transition: background-color .2s linear;
       .text{
          p {
            a{
        text-decoration: none;
        background-color: #000000;
        transition: background-color .2s linear;
          }
        }
      }
    }
    h3{
      span{
        text-transform: none;
        position: absolute;
        font-weight: 600;
        font-size: 50%;
        padding-left: 20px;
        color: #ffffff!important;
        line-height: 50%;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 20px;
        align-items: center;
        // overflow:hidden;


          &:after {
            content: '';
            position: relative;
            display: block;
            // top: 3px;
            // left: 118px;
            height: 2px;
            width: 188px;
            background: #ffffff;
            .has-mobile-nav & {
              width:100%;
            }
          }

      }
    }

  .text{
    margin: inherit;
    padding-left: 20px;

    p{
      font-size: 170%;
      line-height: 120%;
      font-weight: 800;
      text-transform: uppercase;
      color: #ffffff;
      // margin-bottom: 2rem;
      a{
        background-color: $red;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        border: 2px solid $lt-g;
        padding: 15px 30px 15px 37px;
        transition: background-color .2s linear;

        &:after{
          content:'\f178';
          font-family: FontAwesome;
          margin-left: 7px;
          // margin-right: 10px;
        }
        &:hover{
          text-decoration: none;
          background-color: #000000;
          transition: background-color .2s linear;
        }
      }

    }
  }
// }
}
}
.tab-slider-link{
      background-color: #ef1443;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      border: 2px solid #e7eaec;
      padding: 15px 30px 15px 37px;
      transition: all .2s linear;
      &:after{
        content:'\f178';
        font-family: FontAwesome;
        margin-left: 7px;
        // margin-right: 10px;
      }
      &:hover{
        text-decoration: none;
        background-color: #000000;
        transition: all .2s linear;
        color: #ffffff;

      }
}
// @media only screen and (max-width: ) {
//
// }


}//end user_mode
